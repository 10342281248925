exports.components = {
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kamishichi-js": () => import("./../../../src/pages/kamishichi.js" /* webpackChunkName: "component---src-pages-kamishichi-js" */),
  "component---src-pages-message-js": () => import("./../../../src/pages/message.js" /* webpackChunkName: "component---src-pages-message-js" */),
  "component---src-pages-outline-js": () => import("./../../../src/pages/outline.js" /* webpackChunkName: "component---src-pages-outline-js" */),
  "component---src-pages-realestate-js": () => import("./../../../src/pages/realestate.js" /* webpackChunkName: "component---src-pages-realestate-js" */),
  "component---src-pages-restaurant-js": () => import("./../../../src/pages/restaurant.js" /* webpackChunkName: "component---src-pages-restaurant-js" */),
  "component---src-pages-shinnyo-js": () => import("./../../../src/pages/shinnyo.js" /* webpackChunkName: "component---src-pages-shinnyo-js" */),
  "component---src-templates-info-post-js": () => import("./../../../src/templates/info-post.js" /* webpackChunkName: "component---src-templates-info-post-js" */)
}

